.parent {
  position: relative;
  right: 0px;
}
.openButton {
  cursor: pointer;
}
.arrowImage {
  width: 2rem;
}

.arrowParent {
  /* display: none; */
}
.hr {
  margin-top: 1rem;
  border-color: gainsboro;
  opacity: 0.5;
}

@media only screen and (max-width: 800px) {
  .parrent {
    position: absolute;
    right: 0px;
    transition: width 0.3s ease-in-out; /* Smooth transition */
  }
  .opened {
    width: 16rem;
  }
  .closed {
    width: 5rem;
  }
  .arrowParent {
    display: block;
    margin-top: 2rem;
    text-align: left;
    margin-left: 1rem;
  }
}
