#image {
    z-index: 0;
}
#image img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 820px;
}
#text {
    z-index: 0;
    padding: 18px 38px;
}

#hamburger {
    z-index: 1002;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    right: 30px;
    background-color: #e20025;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 2px 2px 10px rgba(10, 10, 10, 0.3);
    transition: all 0.2s ease-in-out;
}
#hamburger .icon-bar {
    display: block;
    background-color: #fff;
    width: 22px;
    height: 2px;
    transition: all 0.3s ease-in-out;
}
#hamburger .icon-bar + .icon-bar {
    margin-top: 4px;
}

.nav {
    z-index: 1001;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.48);
    cursor: pointer;
    transition: all 0.1s ease-in;
}

#nav-2-1.show {
    transition-delay: 0;
    transform: translateY(-50%) translateX(-150%);
}
#nav-2-2.show {
    transition-delay: 0.25s;
    transform: translateY(-150%) translateX(-50%);
}

#nav-3-1.show {
    transition-delay: 0;
    transform: translateY(0%) translateX(-160%);
}
#nav-3-2.show {
    transition-delay: 0.25s;
    transform: translateY(-120%) translateX(-120%);
}
#nav-3-3.show {
    transition-delay: 0.3s;
    transform: translateY(-160%) translateX(0%);
}


#nav-4-1.show {
    transition-delay: 0;
    transform: translateY(20%) translateX(-195%);
}
#nav-4-2.show {
    transition-delay: 0.25s;
    transform: translateY(-90%) translateX(-170%);
}
#nav-4-3.show {
    transition-delay: 0.3s;
    transform: translateY(-170%) translateX(-90%);
}
#nav-4-4.show {
    transition-delay: 0.35s;
    transform: translateY(-195%) translateX(20%);
}


#nav-5-1.show {
    transition-delay: 0;
    transform: translateY(25%) translateX(-250%);
}
#nav-5-2.show {
    transition-delay: 0.25s;
    transform: translateY(-85%) translateX(-235%);
}
#nav-5-3.show {
    transition-delay: 0.3s;
    transform: translateY(-180%) translateX(-180%);
}
#nav-5-4.show {
    transition-delay: 0.35s;
    transform: translateY(-235%) translateX(-85%);
}
#nav-5-5.show {
    transition-delay: 0.35s;
    transform: translateY(-250%) translateX(25%);
}

#hamburger.show {
    bottom: 30px;
    right: 30px;
    box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.48);
}
#hamburger.show #wrapper {
    transition: transform 0.4s ease-in-out;
    transform: rotateZ(90deg);
}

#hamburger.show #one {
    transform: translateY(6px) rotateZ(45deg) scaleX(0.9);
}
#hamburger.show #thr {
    transform: translateY(-6px) rotateZ(-45deg) scaleX(0.9);
}
#hamburger.show #two {
    opacity: 0;
}

.nav.show {
    transition-duration: 0.3s;
    transition-delay: 0.2s;
    visibility: visible;
    opacity: 1;
}
