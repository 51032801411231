
.arrows {
    position: relative;
   /**/
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 50px;
   /**/
    height: 50px;
    transform: translate(-50%, -50%);
}
.arrows:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 12px solid rgba(0, 0, 0, 0.7);
    border-bottom: 12px solid rgba(0, 0, 0, 0.7);
    transform: translate(12px, 90px) rotate(-45deg);
    animation: arrows 3s linear infinite;
}
.arrows:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 12px solid rgba(0, 0, 0, 0.7);
    border-bottom: 12px solid rgba(0, 0, 0, 0.7);
    transform: translate(45px, 0px) rotate(-45deg);
    animation: arrows 3s linear infinite -1.5s;
}
@keyframes arrows {
    0% {
        border-left: 12px solid rgba(0, 0, 0, 0);
        border-bottom: 12px solid rgba(0, 0, 0, 0);
        transform: translate(-6px, -40px) rotate(-45deg);
   }
    10%, 90% {
        border-left: 12px solid rgba(0, 0, 0, 0);
        border-bottom: 12px solid rgba(0, 0, 0, 0);
   }
    50% {
        border-left: 12px solid rgba(0, 0, 0, 0.7);
        border-bottom: 12px solid rgba(0, 0, 0, 0.7);
        transform: translate(-6px, 0px) rotate(-45deg);
   }
    100% {
        border-left: 12px solid rgba(0, 0, 0, 0);
        border-bottom: 12px solid rgba(0, 0, 0, 0);
        transform: translate(-6px, 40px) rotate(-45deg);
   }
}
